/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import BasePage from "./BasePage";
import { Logout, Login } from "./modules/Auth";
import ErrorPage from "./modules/Errors/ErrorPage";

import Swal from "sweetalert2";
import { toast } from "react-toastify";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  const params = new URLSearchParams(window.location.search);
  const queries = Object.fromEntries(params.entries());

  const history = useHistory();
  if (queries.session_id) {
    // Swal.fire({
    //   icon: "success",
    //   html: "<b>Subscription Successful</b><p>Check your email for instructions on how to proceed</p>",
    // });
    toast.success("Subscription Successful!");
    history.replace({ search: "" });
    history.push("/library");
  }
  return (
    <Switch>
      {!isAuthorized ? (
        <Route
          path={[
            "/auth/login/:status?/:auth_token?/:refresh_token?",
            "/teach/auth/login/:status?/:auth_token?/:refresh_token?",
          ]}
          component={Login}
        />
      ) : queries.redirectTo ? (
        <Redirect from="/auth" to={`${queries.redirectTo}?redirect=1`} />
      ) : (
        <Redirect from="/auth" to="/library" />
      )}

      <Route path="/logout" component={Logout} />
      <Route path="/error" component={ErrorPage} />
      <BasePage />
    </Switch>
  );
}
